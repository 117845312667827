/**
 * @file Translation overrides for the client.
 */

import translationEn from './translations/en.json';
import translationFr from './translations/fr.json';

/** @type {import('@verosource/myhealth-web/src/config/translations').TranslationsConfig} */
const translations = {
  en: {
    translation: translationEn,
  },
  fr: {
    translation: translationFr,
  },
};

export default translations;
