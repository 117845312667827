/* eslint { import/no-anonymous-default-export: 0 } */

import app from './appConfig';
import network from './networkConfig';
import translations from './translations';

export default {
  app,
  network,
  translations,
};
